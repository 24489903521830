<template>
  <div class="card-branco">
    <div class="d-flex gap-3 mb-3 align-items-center">
      <router-link to="/" class="btnVoltar">
        <font-awesome-icon icon="circle-left" />
      </router-link>
      <h1>Cidades Agendadas</h1>
    </div>
    <div class="container-fluid">
      <table class="table table-bordered table-striped">
          <thead>
            <tr class="text-center">
                <th>
                  Estado
                </th>
                <th>
                  Cidade
                </th>
                <th>
                  Tipo Coleta
                </th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-center">
                <td>Amazonas</td>
                <td>Manaus</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Bahia</td>
                <td>Itabuna</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Bahia</td>
                <td>Mata de São João</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Bahia</td>
                <td>Salvador</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Ceará</td>
                <td>Caucaia</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Ceará</td>
                <td>Lagoa Sapiranga - Fortaleza</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Distrito Federal</td>
                <td>Brasília</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Goiás</td>
                <td>Minaçu</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Maranhão</td>
                <td>São Luís</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Minas Gerais</td>
                <td>Belo Horizonte</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Minas Gerais</td>
                <td>Divinópolis</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Minas Gerais</td>
                <td>Fronteira</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Minas Gerais</td>
                <td>Lavras</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Mato Grosso</td>
                <td>Várzea Grande</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Mato Grosso</td>
                <td>Cuiabá</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Pará</td>
                <td>Marabá</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraíba</td>
                <td>João Pessoa</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Piauí</td>
                <td>Teresina</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Pernambuco</td>
                <td>Recife</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Curitiba</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Araucária</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Maringá</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Ponta Grossa</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Toledo</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Cascavel</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>São José dos Pinhais</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Londrina</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Foz do Iguaçu</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Colombo</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Guarapuava</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Paranaguá</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Araucária</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Rocador</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Apucarana</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Pinhais</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Campo Largo</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Arapongas</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Almirante Tamandaré</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Piraquara</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Cambé</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Fazenda Rio Grande</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Sarandi</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Campo Mourão</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Francisco Beltrão</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Paranavaí</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Pato Branco</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Tijucas do Sul</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Nova Londrina</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Toledo</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Foz do Iguaçu</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Colombo</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Guarapuava</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Paraná</td>
                <td>Araucária</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio de Janeiro</td>
                <td>Rio de Janeiro</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio de Janeiro</td>
                <td>Nova Iguaçu</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio de Janeiro</td>
                <td>Niterói</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Norte</td>
                <td>Natal</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Sul</td>
                <td>Porto Alegre</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Sul</td>
                <td>Viamão</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Sul</td>
                <td>Pelotas</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Sul</td>
                <td>Canoas</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Sul</td>
                <td>Santa Maria</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Sul</td>
                <td>Gravataí</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Sul</td>
                <td>Novo Hamburgo</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Sul</td>
                <td>São Leopoldo</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Sul</td>
                <td>Rio Grande</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Sul</td>
                <td>Alvorada</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Sul</td>
                <td>Passo Fundo</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Sul</td>
                <td>Sapucaia do Sul</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Sul</td>
                <td>Uruguaiana</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Sul</td>
                <td>Santa Cruz do Sul</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Sul</td>
                <td>Bagé</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Sul</td>
                <td>Bento Gonçalves</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Sul</td>
                <td>Erechim</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Rio Grande do Sul</td>
                <td>Caxias do Sul</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Santa Catarina</td>
                <td>Balneário Camboriú</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Santa Catarina</td>
                <td>Jaraguá do Sul</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Santa Catarina</td>
                <td>Palhoça</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Santa Catarina</td>
                <td>Lages</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Santa Catarina</td>
                <td>Brusque</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Santa Catarina</td>
                <td>Tubarão</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Navegantes</td>
                <td>Tubarão</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Santa Catarina</td>
                <td>Joinville</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Santa Catarina</td>
                <td>Florianópolis</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Santa Catarina</td>
                <td>Blumenau</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Santa Catarina</td>
                <td>São José</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Balneario camboriú</td>
                <td>São José</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Santa Catarina</td>
                <td>Chapecó</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Santa Catarina</td>
                <td>Itajaí</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Santa Catarina</td>
                <td>Criciúma</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>São Paulo</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Paulínia</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Guarulhos</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Campinas</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>São Bernardo do Campo</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Santo André</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Santo André</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Osasco</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>São José dos Campos</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Ribeirão Preto</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Sorocaba</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Mauá</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>São José do Rio Preto</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Cruzeiro</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Santos</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Mogi das Cruzes</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Mogi Guaçu</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Diadema</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Jundiaí</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Carapicuíba</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Piracicaba</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Dracaena</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Mairiporã</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Guarujá</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Birigui</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Indaiatuba</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>São Paulo</td>
                <td>Cajamar</td>
                <td>Venda / Aluguel</td>
            </tr>
            <tr class="text-center">
                <td>Mato Grosso do Sul</td>
                <td>Campo Grande</td>
                <td>Venda / Aluguel</td>
            </tr>
          </tbody>
      </table>
    </div>
  </div>
</template>
<script setup>

</script>

<style scoped>
.ativo svg {
  color: var(--roxo);
  background-color: initial;
  margin-left: initial;
}
</style>