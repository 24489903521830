<template>
  <template v-if="!emFoco">
    <div class="d-flex">
      <font-awesome-icon
        icon="spinner" 
        class="spinner"
      />
      <h3>Carregando...</h3>
    </div>
  </template>
  <template v-else>
    <div>
      <div class="d-flex gap-3">
        <router-link
          class="btnBranco mb-3"
          to="/relatorio-amostras"
        >
          Relatório de Amostras
        </router-link>
        <router-link
          class="btnBranco mb-3"
          to="/evolucao-coleta"
        >
          Evolução de Amostras
        </router-link>
        <router-link
          class="btnBranco mb-3"
          to="/relatorio-atividades"
        >
          Relatório de Atividades
        </router-link>
        <router-link
          class="btnBranco mb-3"
          to="/cidades-agendadas"
        >
          Cidades agendadas
        </router-link>
      </div>
      <section class="card-branco">
        <div class="d-flex justify-content-between gap-3 align-items-center">
          <h1>Dashboard do Administrador</h1>
          <div>
            <MenuUsuario />
          </div>
        </div>
      </section>
      <section class="d-flex mt-2 gap-2">
        <CardContagemDashAdm
          class="card-branco"
          :icone="['fas', 'file-lines']"
          :contagem="emFoco?.countAmostras"
          descricao="De amostras no banco"
        />
        <CardContagemDashAdm
          class="card-branco"
          :icone="['fas', 'users']"
          :em-foco="emFoco"
          :contagem="emFoco?.countUsuarios"
          descricao="Usuários na plataforma"
        />
        <CardContagemDashAdm
          class="card-branco"
          :icone="['fas', 'users']"
          :contagem="emFoco?.countUsuarios"
          descricao="Novos usuários neste mês"
        />
        <CardContagemDashAdm
          class="card-branco"
          :icone="['fas', 'users']"
          :contagem="emFoco?.usuariosAtivos"
          descricao="Usuários ativos na plataforma"
        />
        <CardContagemDashAdm
          class="card-branco"
          :icone="['fas', 'users']"
          :contagem="emFoco?.countNovasAssinaturas"
          descricao="novas assinaturas neste mês"
        />
      </section>
      <div class="d-flex mt-2 gap-2">
        <div class="w-100">
          <MapaAmostra 
            :lista-amostras-estado="emFoco?.countAmostrasEstado"
          />
        </div>
        <div class="w-100">
          <NovosUsuariosCollapseTable
            class="mb-2"
            :lista-usuario="emFoco?.novosUsuarios"
          />
          <NovasAssinaturasCollapseTable 
            :lista-assinatuta="emFoco?.novasAssinaturas"
          />
        </div>
      </div>
    </div>
  </template>
</template>

<script setup>
import CardContagemDashAdm from '@/components/CardContagemDashAdm.vue';
import NovasAssinaturasCollapseTable from '@/components/collapseTable/NovasAssinaturasCollapseTable.vue';
import NovosUsuariosCollapseTable from '@/components/collapseTable/NovosUsuariosCollapseTable.vue';
import MapaAmostra from '@/components/MapaAmostra.vue';
import MenuUsuario from '@/components/MenuUsuario.vue';
import { useDashboardAdmStore } from '@/store/dasboardAdm.store';
import { storeToRefs } from 'pinia';

const dasboardAdmStore = useDashboardAdmStore()
const { emFoco } = storeToRefs(dasboardAdmStore)
async function iniciar(){
  await dasboardAdmStore.buscarTudo()
}
iniciar()

</script>

<style scoped>
.card-cinza{
  background-color: var(--cinzaClaro);
  padding: 1rem;
  max-width: 22.625rem;
  border-radius: 0.875rem;
}

.trabalho-realizado span{
  font-size: 3.75rem;
  font-weight: 500
}

.trabalho-realizado p{
  font-size: 1.13rem;
  font-weight: 500
}

.data-expiracao span{
  color: #ee3b2b;
  font-weight: 500;
}

.data-expiracao{
  border: 2px solid #E5BA38;
  padding: 0 1rem;
  max-width: 22.625rem;
  border-radius: 0.875rem;
}

.card-roxo{
  background-image: url(../../assets/imagens/bgRoxo.jpg);
}

.card-ciano{
  background-image: url(../../assets/imagens/bgCiano.jpg);
}

.card-link{
  color: var(--branco);
  width: 100%;
  padding: 20px;
  border-radius: 32px;
}

.card-link h1{
  color: var(--branco);
}

.card-link a{
  color: var(--branco);
  text-wrap: nowrap;
  font-size: 1.25rem;
}

.card-link svg{
  max-width: 20px;
}
</style>