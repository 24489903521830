export default {
  'ZAP Imóveis ': {
    nome: 'ZAP Imóveis',
    valor: 'https://www.zapimoveis.com.br',
  },
  'Redes Imóveis': {
    nome: 'Redes Imóveis',
    valor: 'https://www.unaimoveisconectados.com.br',
  },
  'Chaves na Mão': {
    nome: 'Chaves na Mão',
    valor: 'https://www.chavesnamao.com.br',
  },
};
