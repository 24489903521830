import { defineStore } from 'pinia';
import { useAlertStore } from '@/store/alert.store';
// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const useEvolucaoColetaStore = defineStore('evolucaoColeta', {
  state: () => ({
    amostra: null,
    chamadasPendentes: {
      amostra: null
    },
    erro: null,
  }),
  actions: {
    async totalAmostraPedido(payload){
      this.chamadasPendentes.amostra = true;
      const alertStore = useAlertStore();
      try{
        const request = await this.requestS.get(`${baseUrl}/sample/repository/count`, payload )
        this.amostra = request.count;
        this.chamadasPendentes.amostra = false;
        return request
      } catch(erro){
        alertStore.error(erro);
      }
    }
  },
});
