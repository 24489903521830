import { defineStore } from 'pinia';
import { useAlertStore } from '@/store/alert.store';
// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const useRelatorioStore = defineStore('relatorio', {
  state: () => ({
    lista: [],
    chamadasPendentes: {
      lista: false
    },
    paginacao: {
      links: [],
      current_page: null,
      last_page: null,
      per_page: 20,
      total: null,
    },
    erro: null,
  }),
  actions: {
    async relatorioAmostras (params = {}){
      this.chamadasPendentes.lista = true;
      const alertStore = useAlertStore();
      try{
        const request = await this.requestS.get(`${baseUrl}/sample/repository/search`, params )
        this.lista = request;

        this.paginacao.current_page = request.current_page;
        this.paginacao.last_page = request.last_page;
        this.paginacao.per_page = request.per_page;
        this.paginacao.total = request.total;
        this.paginacao.links = request.links;
        return request
      } catch(erro){
        alertStore.error(erro);
      }
    }
  },
});