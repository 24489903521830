<template>
  <div>
    <a
      v-if="lista"
      :href="lista.pdfPrint"
      class="btnBranco mb-3"
      target="_blank"
      style="width: max-content"
    >
      <font-awesome-icon icon="file-pdf" />
      Gerar pdf
    </a>
    <div class="card-branco">
      <div class="d-flex gap-3 mb-3 align-items-center">
        <router-link to="/" class="btnVoltar">
          <font-awesome-icon icon="circle-left" />
        </router-link>
        <h1>Relatório Atividades</h1>
      </div>
      <strong
        class="pb-2 d-block"
        style="color: var(--roxo)"
      >
        Buscar
      </strong>
      <form @submit.prevent="onSubmitRelatorio">
        <div class="d-flex gap-3 w-100 mb-2 align-items-center">
          <div class="mb-3 w-100">
            <LabelFromYup
              name="updated_at_gt"
              :schema="schema"
            />
            <Field
              name="updated_at_gt"
              type="date"
              class="inputtext"
              maxlength="10"
            />
            <ErrorMessage
              class="error-msg"
              name="updated_at_gt"
            />
          </div>

          <div class="mb-3 w-100">
            <LabelFromYup
              name="updated_at_lt"
              :schema="schema"
            />
            <Field
              name="updated_at_lt"
              type="date"
              class="inputtext"
              maxlength="10"
            />
            <ErrorMessage
              class="error-msg"
              name="updated_at_lt"
            />
          </div>
        </div>

        <div class="d-flex flex-column align-items-center mt-4">
          <button
            class="btnAmarelo"
            :disabled="isSubmitting"
          >
            Ver dados
          </button>
          <font-awesome-icon
            v-show="isSubmitting"
            icon="spinner"
            class="spinner mt-3"
          />
        </div>
      </form>

      <div v-if="lista">
        <table class="tabela w-100 mt-3">
          <thead>
            <tr>
              <th>Atividade</th>
              <th>Venda</th>
              <th>Aluguel</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Opiniões de Mercado</td>
              <td>{{ lista.opinioesVenda || 0 }}</td>
              <td>{{ lista.opinioesAluguel || 0 }}</td>
              <td>{{ lista.totalOpinioes || 0 }}</td>
            </tr>
            <tr>
              <td>Análises de Mercado</td>
              <td>{{ lista.analisesVenda || 0 }}</td>
              <td>{{ lista.analisesAluguel || 0 }}</td>
              <td>{{ lista.totalAnalises || 0 }}</td>
            </tr>
            <tr>
              <td>PTAMs</td>
              <td>{{ lista.laudosVenda || 0 }}</td>
              <td>{{ lista.laudosAluguel || 0 }}</td>
              <td>{{ lista.totalLaudos || 0 }}</td>
            </tr>
            <tr>
              <td>Pesquisas Inteligente</td>
              <td>{{ lista.totalPicsVenda || 0 }}</td>
              <td>{{ lista.totalPicsAluguel || 0 }}</td>
              <td>{{ lista.totalPics || 0 }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { relatorio as schema } from '@/consts/formSchema';
import { useAlertStore } from '@/store/alert.store';
import { useRelatorioMensalStore } from '@/store/relatorioMensal.store';
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, useForm } from 'vee-validate';
import { onMounted } from 'vue';


const alertStore = useAlertStore();
const relatorioMensalStore = useRelatorioMensalStore()

const { lista } = storeToRefs(relatorioMensalStore);

const {
  isSubmitting, handleSubmit, values, resetForm
} = useForm();


const onSubmitRelatorio = handleSubmit(async (values) => {
  try {
    const payload = {
      ...values,
    }
    await relatorioMensalStore.totalAmostraPedido(payload);
  } catch (error) {
    alertStore.error(error);
  }
})

onMounted(() => {
  relatorioMensalStore.lista = null;
});
</script>

<style scoped>
.ativo svg {
  color: var(--roxo);
  background-color: initial;
  margin-left: initial;
}
</style>