<template>
  <div class="card-branco">
    <div class="d-flex gap-3 mb-3 align-items-center">
      <router-link to="/" class="btnVoltar">
        <font-awesome-icon icon="circle-left" />
      </router-link>
      <h1>Evolução de Coleta de Amostras</h1>
    </div>
    <strong
      class="pb-2 d-block"
      style="color: var(--roxo)"
    >
      Buscar
    </strong>
    <form @submit.prevent="onSubmitColeta">
      <div class="d-flex gap-3 w-100 mb-2 align-items-center">
        <div class="mb-3 w-100">
          <LabelFromYup
            name="updated_at_gt"
            :schema="schema"
          />
          <Field
            name="updated_at_gt"
            type="date"
            class="inputtext"
            maxlength="10"
          />
          <ErrorMessage
            class="error-msg"
            name="updated_at_gt"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            name="updated_at_lt"
            :schema="schema"
          />
          <Field
            name="updated_at_lt"
            type="date"
            class="inputtext"
            maxlength="10"
          />
          <ErrorMessage
            class="error-msg"
            name="updated_at_lt"
          />
        </div>
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button
          class="btnAmarelo"
          :disabled="isSubmitting"
        >
          Buscar
        </button>
        <font-awesome-icon
          v-show="isSubmitting"
          icon="spinner"
          class="spinner mt-3"
        />
      </div>
    </form>

    <div v-if="amostra">
      <h1>Coletas</h1>
      <GChart
        type="ColumnChart"
        :data="[
          ['', 'Coletas'],
          ['', amostra]
        ]"
        :options="options"
      />
    </div>
  </div>
</template>

<script setup>
import { evolucao as schema } from '@/consts/formSchema';
import { useAlertStore } from '@/store/alert.store';
import { useEvolucaoColetaStore } from '@/store/evolucaoColeta.store';
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, useForm } from 'vee-validate';
import { ref } from 'vue';
import { GChart } from 'vue-google-charts';

const alertStore = useAlertStore();

const evolucaoColetaStore = useEvolucaoColetaStore()
const { amostra } = storeToRefs(evolucaoColetaStore);

const {
  errors, isSubmitting, handleSubmit, values
} = useForm({
  validationSchema: schema,
});

const options = {
  legend: { position: 'bottom' },
  hAxis: {},
  vAxis: {}
}

const onSubmitColeta = handleSubmit(async (values) => {
  try {
    const payload = {
      ...values,
    }
    await evolucaoColetaStore.totalAmostraPedido(payload);
  } catch (error) {
    alertStore.error(error);
  }
})

</script>

<style scoped>
.ativo svg {
  color: var(--roxo);
  background-color: initial;
  margin-left: initial;
}
</style>