import { defineStore } from 'pinia';
import { useAlertStore } from '@/store/alert.store';
// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const useRelatorioMensalStore = defineStore('RelatorioMensal', {
  state: () => ({
    lista: [],
    chamadasPendentes: {
      lista: null
    },
    erro: null,
  }),
  actions: {
    async totalAmostraPedido(payload){
      this.chamadasPendentes.lista = true;
      const alertStore = useAlertStore();
      try{
        const request = await this.requestS.get(
          `${baseUrl}/relatorio-mensal/show`, 
          payload 
        )
        this.lista = request;
        this.chamadasPendentes.lista = false;
        return request
      } catch(erro){
        alertStore.error(erro);
      }
    }
  },
});
