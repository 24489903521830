<template>
  <div class="card-branco">
    <div class="d-flex gap-3 mb-3 align-items-center">
      <router-link
        to="/pesquisaInteligente"
        class="btnVoltar"
      >
        <font-awesome-icon icon="circle-left" />
      </router-link>
      <h1>{{ route?.meta?.título }}</h1>
    </div>
    <div class="py-3 px-3 bordaAmarela mb-3">
      <p>
        A Pesquisa Inteligente para o Cliente, é realizada para agilizar o processo de busca
        e otimizar o seu tempo e a parceria entre imobiliárias. Isto ocorre de forma fácil,
        basta inserir o endereço do imóvel que o seu cliente esta buscando, a tipologia e o
        raio para esta pesquisa, nós do Avalion trazemos as amostras de nosso banco de dados. Esta pesquisa ainda
        necessita do seu toque especial, que é escolher os imóveis similares ao imóvel que o seu cliente esta
        procurando. A Pesquisa Inteligente vai mudar o jeito de você procurar imóveis para o seu cliente, agora você vai
        entregar uma pesquisa personalizada com seus dados e foto.
      </p>
    </div>
    <Form
      v-slot="{ errors, isSubmitting }"
      :validation-schema="schema"
      @submit="getImoveis"
    >
      <div class="hidden-field d-flex w-100 gap-3">
        <div class="hidden-field mb-2 w-25">
          <LabelFromYup
            name="cep"
            hidden
            :schema="schema"
          />
          <Field
            v-model="cep"
            name="cep"
            type="text"
          />
        </div>

        <div class="hidden-field mb-2 w-75">
          <LabelFromYup
            name="endereco"
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="enderecoCompleto"
            name="endereco"
            type="text"
          />
        </div>

        <div class="hidden-field mb-2 w-75">
          <LabelFromYup
            name="cidade"
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="cidade"
            name="cidade"
            type="text"
          />
        </div>

        <div class="hidden-field mb-2 w-75">
          <LabelFromYup
            name="estado"
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="estado"
            name="estado"
            type="text"
          />
        </div>

        <div class="hidden-field mb-2 w-75">
          <LabelFromYup
            name="bairro"
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="bairro"
            name="bairro"
            type="text"
          />
        </div>
      </div>

      <div>
        <label>Endereço<span class="required">*</span></label>
        <GMapAutocomplete
          placeholder="Endereço"
          style="font-size: medium"
          class="inputtext mb-3"
          @place_changed="getEndereco"
        />
      </div>

      <div class="d-flex gap-3 w-100">
        <div class="mb-3 w-100">
          <LabelFromYup
            name="tipo_imovel"
            :schema="schema"
          />
          <Field
            v-model="tipo_imovel"
            name="tipo_imovel"
            as="select"
            class="inputtext"
            :class="{ 'error': errors.tipo_imovel }"
            :required="true"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(tipoImovel)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.valor }}
            </option>
          </Field>
          <ErrorMessage
            name="tipo_imovel"
            class="error-msg"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            name="tipo_negocio"
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="tipo_negocio"
            name="tipo_negocio"
            as="select"
            class="inputtext"
            :class="{ 'error': errors.tipo_negocio }"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(tipoNegocio)"
              :key="item.valor"
              :value="item.valor"
            >
              {{ item.valor }}
            </option>
          </Field>
          <ErrorMessage
            name="tipo_negocio"
            class="error-msg"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            name="radius"
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="radius"
            name="radius"
            as="select"
            class="inputtext"
            :class="{ 'error': errors.radius }"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(raioBusca)"
              :key="item.value"
              :value="item.valor"
            >
              {{ item.nome }}
            </option>
          </Field>
          <ErrorMessage
            class="error-msg"
            name="radius"
          />
        </div>
      </div>



      <div class="d-flex w-100 gap-3 my-2 align-items-end">
        <div class="w-100 mb-3">
          <LabelFromYup
            name="latitude"
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="coords.lat"
            name="latitude"
            type="text"
            placeholder="latitude"
            class="inputtext"
            :class="{ 'error': errors.latitude }"
          />
          <ErrorMessage
            class="error-msg"
            name="latitude"
          />
        </div>

        <div class="w-100 mb-3">
          <LabelFromYup
            name="longitude"
            :schema="schema"
            :required="true"
          />
          <Field
            v-model="coords.lng"
            name="longitude"
            type="text"
            placeholder="longitude"
            class="inputtext"
            :class="{ 'error': errors.longitude }"
          />
          <ErrorMessage
            class="error-msg"
            name="longitude"
          />
        </div>
      </div>

      <div class="d-flex w-100 gap-3 my-2 mb-3">
        <div class="w-50">
          <div class="d-flex gap-3">
            <label>
              <Field
                v-model="usarArea"
                name="usarArea"
                type="radio"
                value="area_total"
                class="inputcheckbox"
              />
              <span class="ps-2">Área Total</span>
            </label>
            <label>
              <Field
                v-model="usarArea"
                name="usarArea"
                type="radio"
                value="area_util"
                class="inputcheckbox"
              />
              <span class="ps-2">Área Útil</span>
            </label>
          </div>
          <div class="">
            <LabelFromYup
              name="area_privativa"
              :schema="schema"
            />
            <Field
              v-model="area_media"
              name="area_privativa"
              type="number"
              class="inputtext"
              min="1"
              max="1000"
            />
            <ErrorMessage
              class="error-msg"
              name="area_privativa"
            />
          </div>
        </div>
        <div class="d-flex gap-3">
          <div>
            <div class="d-flex">
              <Field
                name="exibir_curriculo"
                type="checkbox"
                :value="true"
                class="inputcheckbox"
              />
              <LabelFromYup
                name="exibir_curriculo"
                :schema="schema"
                class="ps-2"
              />
            </div>
            <div class="d-flex">
              <Field
                name="exibir_historico"
                type="checkbox"
                :value="true"
                class="inputcheckbox"
              />
              <LabelFromYup
                name="exibir_historico"
                :schema="schema"
                class="ps-2"
              />
            </div>
            <div class="d-flex">
              <Field
                v-model="exibir_amostras_na_rua"
                name="exibir_amostras_na_rua"
                type="checkbox"
                class="inputcheckbox"
                :value="true"
              />
              <LabelFromYup
                name="exibir_amostras_na_rua"
                :schema="schema"
                class="ps-2"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="modal"
        class="modal-overlay"
        @click="closeModal"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          @click.stop
        >
          <div class="modal-content">
            <div class="modal-header justify-content-between d-flex justify-content-between">
              <h3>Amostras encontradas</h3>
              <button
                class="Modalclose"
                type="button"
                @click="closeModal"
              >
                <font-awesome-icon icon="circle-xmark" />
              </button>
            </div>
            <div class="bordaCiano modal-body mt-3 px-2 py-3">
              <p class="mb-2 text-start">
                Foram encontradas <strong>{{ lista.length }}</strong> amostras num raio de <strong>{{ radius }}</strong>
                metros do endereço <strong>{{ enderecoCompleto }}</strong>
              </p>
              <p class="text-start">
                Agora remova ou filtre as amostras para atingir um resultado mais preciso.
                O cálculo será efetuado com as amostras selecionadas.
              </p>
            </div>
            <div class="modal-footer pt-3 py-0">
              <button 
                class="btn btn-secondary"
                type="button"
                @click="closeModal"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column align-items-center mb-4">
        <button
          :disabled="isSubmitting || requisicaoNavegacao === '0'"
          :class="{ 'BtnDisabled': requisicaoNavegacao === '0', 'btnRoxo': true }"
        >
          BUSCAR IMÓVEL
        </button>
        <font-awesome-icon
          v-show="isSubmitting"
          icon="spinner"
          class="spinner mt-3"
        />
      </div>

      <div class="mb-4">
        <GMapMap
          :center="coords"
          :zoom="15"
          map-type-id="terrain"
          style="width: 100%; 
          height: 22rem"
        >
          <GMapMarker
            :key="markerDetails.id"
            :position="markerDetails.position"
            :clickable="true"
            :draggable="false"
          />
          <GMapCircle
            :center="coords"
            :radius="parseInt(radius)"
            :options="{
              fillColor: '#FF0000',
              fillOpacity: 0.2,
              strokeColor: '#FF0000',
              strokeOpacity: 0.5
            }"
          />
          <div v-if="lista.length">
            <GMapMarker
              v-for="imovel in markers"
              :key="imovel._id"
              :position="{
                lat: imovel.location.coordinates[1],
                lng: imovel.location.coordinates[0]
              }"
              :clickable="true"
              :draggable="false"
              @click="openMarker(imovel._id)"
            >
              <GMapInfoWindow
                :closeclick="true"
                :opened="openedMarkerID === imovel._id"
                class="infoImovel"
                @closeclick="openMarker(null)"
              >
                <div>
                  <p class="pb-3">
                    {{ imovel.titulo }}
                  </p>
                  <a
                    target="_blank"
                    :href="imovel.anuncioUrl"
                  >Ver anuncio</a>
                </div>
              </GMapInfoWindow>
            </GMapMarker>
          </div>
        </GMapMap>
      </div>
    </Form>

    <template v-if="!lista.length">
      <p>Imoveis não encontados</p>
    </template>
    <template v-else>
      <div>
        <strong style="color: var(--roxo)">
          Filtrar por:
        </strong>
        <form class="mb-3 d-flex gap-3 flex-wrap align-items-end justify-content-between">
          <div class="d-flex gap-3 align-items-center flex-wrap">
            <div class="d-flex gap-3 align-items-center w-100">
              <div class="w-100">
                <label>Área Mínima</label>
                <input
                  id="area_min"
                  v-model.trim="area_min"
                  placeholder="Área Mínima"
                  class="inputtext"
                  name="area_min"
                  type="number"
                >
              </div>
              <div class="w-100">
                <label>Área Máxima</label>
                <input
                  id="area_max"
                  v-model.trim="area_max"
                  placeholder="Área Máxima"
                  class="inputtext"
                  name="area_max"
                  type="number"
                >
              </div>
              <div class="w-100">
                <label>Valor Mínimo</label>
                <input
                  id="valor_min"
                  v-model.trim="valor_min"
                  placeholder="Valor Mínimo"
                  class="inputtext"
                  name="valor_min"
                  type="text"
                >
              </div>
              <div class="w-100">
                <label>Valor Máximo</label>
                <input
                  id="valor_max"
                  v-model.trim="valor_max"
                  placeholder="Valor Máximo"
                  class="inputtext"
                  name="valor_max"
                  type="text"
                >
              </div>
            </div>
            <div class="d-flex gap-3 align-items-center w-100">
              <div class="w-50">
                <label>Dormitórios</label>
                <div class="d-flex gap-3 align-items-center">
                  <div>
                    <input
                      id="1"
                      v-model="quartos"
                      value="1"
                      class="inputcheckbox"
                      type="checkbox"
                    >
                    <label class="ps-2">1</label>
                  </div>
                  <div>
                    <input
                      id="2"
                      v-model="quartos"
                      value="2"
                      class="inputcheckbox"
                      type="checkbox"
                    >
                    <label class="ps-2">2</label>
                  </div>
                  <div>
                    <input
                      id="3"
                      v-model="quartos"
                      value="3"
                      class="inputcheckbox"
                      type="checkbox"
                    >
                    <label class="ps-2">3</label>
                  </div>
                  <div>
                    <input
                      id="4"
                      v-model="quartos"
                      value="4"
                      class="inputcheckbox"
                      type="checkbox"
                    >
                    <label class="ps-2">4</label>
                  </div>
                </div>
              </div>
              <div class="w-50">
                <label>Vagas de garagem</label>
                <div class="d-flex gap-3 align-items-center">
                  <div>
                    <input
                      id="1"
                      v-model="garagem"
                      value="1"
                      class="inputcheckbox"
                      type="checkbox"
                    >
                    <label class="ps-2">1</label>
                  </div>
                  <div>
                    <input
                      id="2"
                      v-model="garagem"
                      value="2"
                      class="inputcheckbox"
                      type="checkbox"
                    >
                    <label class="ps-2">2</label>
                  </div>
                  <div>
                    <input
                      id="3"
                      v-model="garagem"
                      value="3"
                      class="inputcheckbox"
                      type="checkbox"
                    >
                    <label class="ps-2">3</label>
                  </div>
                  <div>
                    <input
                      id="4"
                      v-model="garagem"
                      value="4"
                      class="inputcheckbox"
                      type="checkbox"
                    >
                    <label class="ps-2">4</label>
                  </div>
                </div>
              </div>
              <div class="w-100">
                <label>Endereço</label>
                <input
                  id="endereco"
                  v-model="endereco"
                  placeholder="Endereço"
                  class="inputtext"
                  name="endereco"
                  type="text"
                >
              </div>
            </div>
          </div>
        </form>
        <button
          type="button"
          class="btnRoxo"
          @click="limparFiltro"
        >
          Limpar filtro
        </button>

        <div class="py-3 px-3 bordaAmarela w-50 my-5 mx-auto">
          <p>
            Exibindo <strong>{{ listaFiltrada.length }}</strong> de um total de
            <strong>{{ lista.length }}</strong> amostras.<br>
            Selecione no mínimo <strong>4 amostras</strong>.
          </p>
        </div>

        <div class="w-100">
          <input
            id="selectAll"
            class="me-2"
            type="checkbox"
            :checked="allSelected"
            @change="toggleSelectAll"
          >
          <label>Selecionar todas amostras</label>
        </div>

        <div class="tabela">
          <table class="w-100">
            <col>
            <col>
            <col>
            <col>
            <col>
            <col>
            <col>
            <col>
            <col>
            <col>
            <col>
            <col>
            <thead>
              <tr>
                <th />
                <th
                  class="ordenagem"
                  @click="sortTable('logradouro' || 'bairro')"
                >
                  Endereço/Bairro
                </th>
                <th
                  class="ordenagem"
                  @click="sortTable('preco')"
                >
                  Valor
                </th>
                <th
                  class="ordenagem"
                  @click="sortTable('area')"
                >
                  Á. Priv.
                </th>
                <th
                  class="ordenagem"
                  @click="sortTable('area_total')"
                >
                  Á. Total
                </th>
                <th
                  class="ordenagem"
                  @click="sortTableValorMetro('area')"
                >
                  R$/m² priv.
                </th>
                <th
                  class="ordenagem"
                  @click="sortTableValorMetro('area_total')"
                >
                  R$/m² total
                </th>
                <th
                  class="ordenagem"
                  @click="sortTable('quartos')"
                >
                  Quartos
                </th>
                <th
                  class="ordenagem"
                  @click="sortTable('vagas')"
                >
                  Vagas
                </th>
                <th
                  class="ordenagem"
                  @click="sortTable('anunciante')"
                >
                  Anunciante
                </th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in listaOrdenada"
                :key="item.id"
              >
                <td>
                  <input
                    id="imovel_selecionado"
                    type="checkbox"
                    name="imovel_selecionado"
                    :checked="checkboxStates[item._id] || false"
                    @click="selecionaImovel(item._id, $event)"
                  >
                </td>
                <td>{{ item.logradouro || item.bairro }}</td>
                <td>R${{ dinheiro(item.preco) }}</td>
                <td>{{ item.area + `m²` }}</td>
                <td>{{ item.area_total + `m²` }}</td>
                <td>R${{ dinheiro(item.preco / item.area) }}</td>
                <td>R${{ dinheiro(item.preco / item.area_total) }}</td>
                <td>{{ item.quartos }}</td>
                <td>{{ item.vagas }}</td>
                <td class="anunciante">
                  {{ item.anunciante }}
                </td>
                <td class="tdBtnAcao">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#itemModal"
                    class="icon"
                    @click="selecionaItem(item)"
                  >
                    <font-awesome-icon icon="pen-to-square" />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="d-flex flex-column align-items-center mt-4">
          <button
            v-show="isSalvarImoveisDisabled"
            class="btnRoxo"
            @click="renderForm"
          >
            Salvar
          </button>
        </div>
      </div>
    </template>

    <section v-if="ativo">
      <div class="py-3 px-3 bordaCiano my-5 mx-auto">
        <p>
          Foram encontradas {{ lista.length }} amostras com as características fornecidas num raio de {{ radius }}
          metros
          de distância do endereço solicitado das quais foram selecionadas {{ amostrasSelecionadas.length }} amostras.
        </p>
      </div>

      <div class="d-flex w-100 gap-3 textCenter">
        <div class="aluguel_opiniao w-50">
          <h2 class="pb-3">
            {{ tipo_negocio }}
          </h2>
          <ul class="mb-0 pe-0">
            <li class="pb-2">
              <strong>Área:</strong> {{ somaValorArea }}m²
            </li>
            <li class="pb-2">
              <strong>Quartos:</strong> {{ quartos.join(" , ") }}
            </li>
            <li class="pb-2">
              <strong>Garagens:</strong> {{ garagem.join(" , ") }}
            </li>
          </ul>
        </div>

        <div class="aluguel_opiniao w-50 textCenter">
          <h2 class="pb-3">
            Pesquisa Inteligente
          </h2>
          <ul class="mb-0 pe-0">
            <h3 class="pb-2">
              R${{ dinheiro(somaValorTotal) }}
            </h3>
            <li class="pb-2">
              <strong>Valor do metro quadrado:</strong>R${{ dinheiro(mediaMetroQuadrado) }}
            </li>
          </ul>
        </div>
      </div>

      <div class="py-3 px-3 bordaCiano my-5 mx-auto">
        <p>
          O valor acima é calculado baseado na média aritmética simples de acordo com as informações
          fornecidas pelo usuário. Não se trata de uma avaliação de mercado do imóvel.
        </p>
      </div>

      <div class="d-flex flex-column align-items-center mt-4">
        <button
          v-show="isSalvarImoveisDisabled"
          class="btnAmarelo"
          @click="enviarPesquisaInteligente"
        >
          Salvar imóveis
        </button>
      </div>
    </section>

    <div
      id="itemModal"
      class="modal fade"
      tabindex="-1"
      aria-labelledby="itemModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div
          class="modal-content"
          style="width: 100%;"
        >
          <div class="modal-header">
            <h5
              id="itemModalLabel"
              class="modal-title"
            >
              {{ selectedItem?.titulo || 'Detalhes do Imóvel' }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body">
            <div class="imovel d-flex gap-5 mt-4">
              <ul>
                <li>Tipo<strong>{{ selectedItem?.tipo }}</strong></li>
                <li>Preço<strong>{{ `R$${dinheiro(selectedItem?.preco)}` }}</strong></li>
                <li>Aréa total<strong>{{ selectedItem?.area_total }}</strong></li>
                <li>Aréa útil<strong>{{ selectedItem?.area_util }}</strong></li>
                <li>Quartos<strong>{{ selectedItem?.quartos }}</strong></li>
                <!-- <li>Banheiro<strong>{{selectedItem?.quartos}}</strong></li> -->
                <li>Suites<strong>{{ selectedItem?.suites }}</strong></li>
                <li>Vagas<strong>{{ selectedItem?.vagas }}</strong></li>
                <li>IPTU<strong>{{ selectedItem?.iptu }}</strong></li>
              </ul>
              <ul>
                <li>Condominio<strong>{{ selectedItem?.condominio }}</strong></li>
                <li>Anunciante<strong>{{ selectedItem?.anunciante }}</strong></li>
                <li>
                  Link do Anuncio
                  <strong>
                    <a
                      :href="selectedItem?.anuncioUrl"
                      target="_blank"
                      rel="noopener noreferrer"
                    >Veja o anuncio
                      completo</a>
                  </strong>
                </li>
                <li>Ano de construção<strong /></li>
                <li>Data da criação<strong>{{ dataFormatada(selectedItem?.createdAt) }}</strong></li>
                <li>Data da atualização<strong>{{ dataFormatada(selectedItem?.updatedAt) }}</strong></li>
              </ul>
            </div>
            <div>
              <strong>Descrição</strong>
              <p v-html="selectedItem?.descricao" />
            </div>
            <div class="mt-4">
              <h1 class="pb-2">
                Imagens
              </h1>
              <div class="d-flex gap-3 flex-wrap">
                <div
                  v-for="(foto, index) in selectedItem?.fotos"
                  :key="index"
                  class="imagem-wrapper"
                >
                  <img
                    :src="foto"
                    alt="Imagem do Imóvel"
                    class="imagem-imovel"
                  >
                  <div class="form-check mt-2">
                    <input
                      :id="'foto-' + index"
                      v-model="fotosSelecionadasPorImovel[selectedItem._id]"
                      class="form-check-input"
                      type="checkbox"
                      :value="foto"
                      :disabled="fotosSelecionadasPorImovel[selectedItem._id]?.length >= 4 && !fotosSelecionadasPorImovel[selectedItem._id]?.includes(foto)"
                    >

                    <label
                      class="form-check-label"
                      :for="'foto-' + index"
                    >
                      Selecionar Foto
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { pesquisaInteligente as schema } from '@/consts/formSchema';
import raioBusca from '@/consts/raioBusca';
import tipoImovel from '@/consts/tipoImovel';
import tipoNegocio from '@/consts/tipoNegocio';
import dataFormatada from '@/helpers/dataFormatada';
import dinheiro from '@/helpers/dinheiro';
import { useAlertStore } from '@/store/alert.store';
import { useImoveisStore } from '@/store/buscaImoveisPorLocalizacao';
import { usePesquisaInteligenteStore } from '@/store/pesquisaInteligente.store';
import { useRequisicaoFinalidaeStore } from '@/store/requisicoesFinalidade.store';
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const alertStore = useAlertStore();
const router = useRouter();
const route = useRoute();

const pesquisaInteligenteStore = usePesquisaInteligenteStore()
const { pesquisaId } = storeToRefs(pesquisaInteligenteStore)

const imoveisStore = useImoveisStore()
const { lista } = storeToRefs(imoveisStore);

const requisicoesFinalidadeStore = useRequisicaoFinalidaeStore()
const { requisicaoNavegacao } = storeToRefs(requisicoesFinalidadeStore);

const checkboxStates = reactive({});
const fotosSelecionadasPorImovel = reactive({});

const cep = ref('')
const bairro = ref('')
const cidade = ref('')
const estado = ref('')
const rua = ref('')
const selectedItem = ref(null);
const exibir_amostras_na_rua = ref(false)
const enderecoCompleto = ref('')
const usarArea = ref('area_util');
const area_media = ref(0)
const dadosForm = ref({})
const tipo_negocio = ref('')
const tipo_imovel = ref('')
const radius = ref('');
const area_min = ref('');
const area_max = ref('');
const valor_min = ref('');
const valor_max = ref('');
const endereco = ref('');
const quartos = ref([])
const garagem = ref([])
const ativo = ref(false)
const modal = ref(false)
const listaOrdenada = ref([]);
const colunaOrdenada = ref(null);
const direcaoOrdenada = ref(null);
const coords = ref({ lat: -15.77972, lng: -47.92972 });
const markerDetails = ref({ id: 1, position: coords.value })
const markers = computed(() => lista.value);
const openedMarkerID = ref(null)

const getUserLocation = () => {
  const isSupported = 'navigator' in window && 'geolocation' in navigator
  if (isSupported) {
    navigator.geolocation.getCurrentPosition((position) => {
      coords.value.lat = position.coords.latitude
      coords.value.lng = position.coords.longitude
    })
  }
}

function renderForm() {
  ativo.value = true
}

const somaValorTotal = computed(() => {
  return somaValorArea.value * mediaMetroQuadrado.value;
});

const somaValorArea = computed(() => {

if (area_media.value > 0) {
  return area_media.value
}

return amostrasSelecionadas.value.reduce((total, item) => {
  return total + item[usarArea.value] / amostrasSelecionadas.value.length
}, 0);
});

const mediaMetroQuadrado = computed(() => {
  let soma = 0;
  let contador = 0;

  for (let i = 0; i < amostrasSelecionadas.value.length; i++) {
    let imovel = amostrasSelecionadas.value[i];
    let areaUsar = imovel[usarArea.value] || imovel.area;

    if (areaUsar) {
      let area = parseFloat(areaUsar);
      let somaRaw = getValorMetroQuadrado(imovel.preco, area);

      if (!isNaN(somaRaw)) {
        soma += somaRaw;
        contador++;
      }
    }
  }

  return contador > 0 ? soma / contador : 0;
});


const getEndereco = (place) => {
  enderecoCompleto.value = place.formatted_address
  const components = place.address_components;

  coords.value.lat = place.geometry.location.lat()
  coords.value.lng = place.geometry.location.lng()

  components?.forEach(component => {
    const types = component.types;
    if (types.includes("postal_code")) {
      cep.value = component.long_name;
    } else if (types.includes("sublocality") || types.includes("sublocality_level_1")) {
      bairro.value = component.long_name;
    } else if (types.includes("administrative_area_level_2")) {
      cidade.value = component.long_name;
    } else if (types.includes("administrative_area_level_1")) {
      estado.value = component.long_name;
    } else if (types.includes("route")) {
      rua.value = component.long_name;
    }
  });
}

async function getImoveis(value) {
  try {
    const params = {
      ...value,
      location: coords.value,
    }
    let resposta
    resposta = await imoveisStore.getImoveisByLocation(params)
    dadosForm.value = { ...value, ...resposta }
    modal.value = true
  } catch (error) {
    alertStore.error(error);
  }
}

const allSelected = computed(() => {
  return listaFiltrada.value.length > 0 && listaFiltrada.value.every(imovel => checkboxStates[imovel._id]);
});

function toggleSelectAll() {
  const newValue = !allSelected.value;
  listaFiltrada.value.forEach(imovel => {
    checkboxStates[imovel._id] = newValue;
  });
}

const listaFiltrada = computed(() => {
  return lista.value.filter(item => {
    const areaMatch =
      (!area_min.value || item.area >= area_min.value) &&
      (!area_max.value || item.area <= area_max.value);

    const valorMatch =
      (!valor_min.value || item.preco >= parseFloat(valor_min.value.replace(/\D/g, ''))) &&
      (!valor_max.value || item.preco <= parseFloat(valor_max.value.replace(/\D/g, '')));

    const quartosMatch =
      quartos.value.length === 0 ||
      quartos.value.includes(item.quartos ? item.quartos.toString() : '');

    const garagemMatch =
      garagem.value.length === 0 ||
      garagem.value.includes(item.vagas ? item.vagas.toString() : '');

    const enderecoMatch =
      !endereco.value ||
      item.logradouro?.toLowerCase().includes(endereco.value.toLowerCase()) ||
      item.bairro.toLowerCase().includes(endereco.value.toLowerCase());

    const ruaMatch =
      !exibir_amostras_na_rua.value ||
      (item.logradouro && item.logradouro.toLowerCase() === rua.value.toLowerCase());
    return areaMatch && valorMatch && quartosMatch && garagemMatch && enderecoMatch && ruaMatch;
  });
});

function limparFiltro() {
  area_min.value = '';
  area_max.value = '';
  valor_min.value = '';
  valor_max.value = '';
  endereco.value = '';
  quartos.value = [];
  garagem.value = [];
}

const isSalvarImoveisDisabled = computed(() => {
  return amostrasSelecionadas.value.length >= 4
});

const amostrasSelecionadas = computed(() => {
  return lista.value
    .filter(imovel => checkboxStates[imovel._id])
    .map(imovel => {
      return {
        ...imovel,
        fotosSelecionadas: fotosSelecionadasPorImovel[imovel._id] || []
      };
    });
});


function selecionaImovel(id, event) {
  checkboxStates[id] = event.target.checked;
}

function selecionaItem(item) {
  selectedItem.value = item;
  if (!fotosSelecionadasPorImovel[item._id]) {
    fotosSelecionadasPorImovel[item._id] = [];
  }
}

async function postContador() {
  try {
    const contador = {
      location: {
        "latitude": coords.value.lat,
        "longitude": coords.value.lng,
      },
      tipo_de_busca: 'pesquisa inteligente',
      endereco: enderecoCompleto.value,
      radius: radius.value,
      tipo_imovel: tipo_imovel.value,
      tipo_negocio: tipo_negocio.value

    }

    await requisicoesFinalidadeStore.contador(contador)
  } catch (error) {
    alertStore.error(error);
  }
}

function enviarPesquisaInteligente() {
  onSubmitPesquisaInteligente(dadosForm.value);
}

async function onSubmitPesquisaInteligente(values) {
  try {
    const payload = {
      ...values,
      location: {
        "longitude": coords.value.lng,
        "latitude": coords.value.lat
      },
      imoveis: lista.value,
      amostrasExcluidas: [],
      imoveisSelecionados: amostrasSelecionadas.value || [],
      id: null
    };

    const resposta = await pesquisaInteligenteStore.salvarItem(payload);
    if (resposta) {
      alertStore.success('Dados salvos com sucesso!');
      router.push({
        name: 'Editar Pesquisa Inteligente',
        params: { pesquisaInteligenteId: pesquisaId.value }
      });
      postContador()
    }
  } catch (error) {
    alertStore.error(error);
  }
}

const toggleSortDirection = (column) => {
  if (colunaOrdenada.value === column) {
    direcaoOrdenada.value = direcaoOrdenada.value === 'asc' ? 'desc' : 'asc';
  } else {
    colunaOrdenada.value = column;
    direcaoOrdenada.value = 'asc';
  }
};

const compareValues = (valueA, valueB) => {

  if (valueA === valueB) return 0;

  return direcaoOrdenada.value === 'asc'
    ? valueA > valueB ? 1 : valueA < valueB ? -1 : 0
    : valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
};

function sortTable(column) {
  toggleSortDirection(column);

  listaOrdenada.value = listaFiltrada.value.slice().sort((a, b) => {
    let valueA = a[column];
    let valueB = b[column];

    if (typeof valueA === 'string' && typeof valueB === 'string') {
      valueA = valueA.toUpperCase();
      valueB = valueB.toUpperCase();
    }
    return compareValues(valueA, valueB)
  });
}

const sortTableValorMetro = (column) => {
  toggleSortDirection(column);

  listaOrdenada.value = listaFiltrada.value.slice().sort((a, b) =>
    compareValues(
      getValorMetroQuadrado(a.preco, a[column]),
      getValorMetroQuadrado(b.preco, b[column]))
  );
};

const getValorMetroQuadrado = (valor, area) => {
  const valorTotal = Number(valor);
  const areaTotal = parseFloat(area);
  return valorTotal / areaTotal;
};

async function iniciar() {
  await requisicoesFinalidadeStore.buscarTudo('pesquisa inteligente')
}
iniciar()

function openMarker(id) {
  openedMarkerID.value = id
}

function closeModal() {
  modal.value = false
}

watch(listaFiltrada, () => {
  listaOrdenada.value = listaFiltrada.value;
}, { immediate: true });

onMounted(() => {
  setTimeout(() => {
    lista.value = [];
  }, 50);
  getUserLocation();
});
</script>

<style scoped>
.GoogleMap {
  height: -moz-max-content;
  height: max-content;
  background: initial;
}

.ativo svg {
  color: var(--roxo);
  background-color: initial;
  margin-left: initial;
}

.fa-file-lines {
  color: var(--branco);
  font-size: 68px;
}

.aluguel_opiniao li {
  list-style: none;
  color: var(--cinzaEscuro);
  text-decoration: none;
}

.aluguel_opiniao {
  padding: 20px;
  border-radius: 7px;
  background-color: var(--cinzaClaro);
}

.anunciante {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 175px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  width: 625px;
  max-width: 100%;
  position: relative;
}

.imagem-wrapper {
  width: 250px;
}

.ordenagem:hover::after {
  content: '▼';
  font-size: 12px;
  padding-left: 2px;
}

.tabela {
  max-height: 500px;
  overflow-y: auto;
}

.tabela::-webkit-scrollbar {
  width: 5px;
}

.tabela::-webkit-scrollbar-thumb {
  background-color: var(--roxo);
}

.tabela::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.tabela thead {
  position: sticky;
  top: 0;
  z-index: 10;
}

.infoImovel {
  max-width: 250px;
}
</style>
