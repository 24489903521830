import { defineStore } from 'pinia';
import { useAlertStore } from '@/store/alert.store';
// eslint-disable-next-line no-undef
const baseUrl = process.env.VUE_APP_API_URL;

export const useCidadeStore = defineStore('Cidade', {
  state: () => ({
    cidade: null,
    listaBairro: null,
    chamadasPendentes: {
      cidade: null,
      listaBairro: null,
    },
    erro: null,
  }),
  actions: {
    async buscarCidade(){
      this.chamadasPendentes.cidade = true;
      const alertStore = useAlertStore();
      try{
        const request = await this.requestS.get(`${baseUrl}/cities` )
        this.cidade = request.cities;
        this.chamadasPendentes.cidade = false;
        return request
      } catch(erro){
        alertStore.error(erro);
      }
    },

    async buscarBairro(estado) {
      this.chamadasPendentes.listaBairro = true;
      this.erro = null;

      try {
        const resposta = await this.requestS.get(`${baseUrl}/neighborhoods-by-city/${estado}`);
        this.listaBairro = resposta.neighborhoods
      } catch (erro) {
        this.erro = erro;
      }
      this.chamadasPendentes.listaBairro = false;
    },
  },
});
