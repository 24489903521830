<template>
  <div v-if="!lista" class="card-branco">
    <div class="d-flex gap-3 mb-3 align-items-center">
      <router-link to="/" class="btnVoltar">
        <font-awesome-icon icon="circle-left" />
      </router-link>
      <h1>Relatório e amostras</h1>
    </div>
    <strong
      class="pb-2 d-block"
      style="color: var(--roxo)"
    >
      Buscar
    </strong>
    <form @submit.prevent="onSubmitAmostras">
      <div class="d-flex gap-3 w-100 mb-2 align-items-center">
        <div class="mb-3 w-100">
          <LabelFromYup
            name="updated_at_gt"
            :schema="schema"
            :required="true"
          />
          <Field
            name="updated_at_gt"
            type="date"
            class="inputtext"
            :class="{ 'error': errors.updated_at_gt }"
            maxlength="10"
          />
          <ErrorMessage 
            class="error-msg"
            name="updated_at_gt"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            name="updated_at_lt"
            :schema="schema"
            :required="true"
          />
          <Field
            name="updated_at_lt"
            type="date"
            class="inputtext"
            :class="{ 'error': errors.updated_at_lt }"
            maxlength="10"
          />
          <ErrorMessage 
            class="error-msg"
            name="updated_at_lt"
          />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2 align-items-center">
        <div class="mb-3 w-100">
          <LabelFromYup
            name="portal"
            :schema="schema"
          />
          <Field
            name="portal"
            as="select"
            class="inputtext"
            :class="{ 'error': errors.portal }"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(portal)"
              :key="item.nome"
              :value="item.nome"
            >
              {{ item.nome }}
            </option>
          </Field>
          <ErrorMessage
            class="error-msg"
            name="portal"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            name="type"
            :schema="schema"
          />
          <Field
            name="type"
            as="select"
            class="inputtext"
            :class="{ 'error': errors.type }"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(tipoImovel)"
              :key="item.nome"
              :value="item.nome"
            >
              {{ item.nome }}
            </option>
          </Field>
          <ErrorMessage
            class="error-msg"
            name="type"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup
            name="business_type"
            :schema="schema"
          />
          <Field
            name="business_type"
            as="select"
            class="inputtext"
            :class="{ 'error': errors.business_type }"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in Object.values(tipoNegocio)"
              :key="item.nome"
              :value="item.nome"
            >
              {{ item.nome }}
            </option>
          </Field>
          <ErrorMessage
            class="error-msg"
            name="business_type"
          />
        </div>
      </div>

      <div class="d-flex gap-3 w-100 mb-2 align-items-center">
        <div class="mb-3 w-100">
          <LabelFromYup 
            name="city" 
            :schema="schema"
            :required="true"
          />
          <Field 
            name="city" 
            as="select"
            class="inputtext"
            :class="{ 'error': errors.city }"
            @change="getBairro"
          >
            <option value="">
              Selecionar
            </option>
            <option
              v-for="item in optionsCity"
              :key="item.valor"
              :value="item.id"
            >
              {{ item.valor }} 
            </option>
          </Field>
          <ErrorMessage 
            class="error-msg"
            name="city"
          />
        </div>

        <div class="mb-3 w-100">
          <LabelFromYup 
            name="neighborhood" 
            :schema="schema"
          />
          <multiselect
            v-model="selectedNeighborhood"
            :options="optionsNeighborhood"
            :multiple="true"
            placeholder="Cidade"
            label="valor"
            name="neighborhood"
            track-by="id"
          />
        </div>
      </div>

      <div class="d-flex gap-3 align-items-center flex-wrap">
        <div class="d-flex gap-3 align-items-center w-100">
          <div class="w-100">
            <label>Área Mínima</label>
            <input
              id="private_area_gt"
              v-model.trim="private_area_gt"
              placeholder="Área Mínima"
              class="inputtext"
              name="private_area_gt"
              type="number"
            >
          </div>
          <div class="w-100">
            <label>Área Máxima</label>
            <input
              id="private_area_lt"
              v-model.trim="private_area_lt"
              placeholder="Área Máxima"
              class="inputtext"
              name="private_area_lt"
              type="number"
            >
          </div>
          <div class="w-100">
            <label>Valor Mínimo</label>
            <input
              id="price_m2_gt"
              v-model.trim="price_m2_gt"
              placeholder="Valor Mínimo"
              class="inputtext"
              name="price_m2_gt"
              type="text"
            >
          </div>
          <div class="w-100">
            <label>Valor Máximo</label>
            <input
              id="price_m2_lt"
              v-model.trim="price_m2_lt"
              placeholder="Valor Máximo"
              class="inputtext"
              name="price_m2_lt"
              type="text"
            >
          </div>
        </div>
        <div class="d-flex gap-3 align-items-center w-100">
          <div class="w-50">
            <label>Dormitórios</label>
            <div class="d-flex gap-3 align-items-center">
              <div>
                <input
                  id="1"
                  v-model="bedrooms"
                  value="1"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">1</label>
              </div>
              <div>
                <input
                  id="2"
                  v-model="bedrooms"
                  value="2"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">2</label>
              </div>
              <div>
                <input
                  id="3"
                  v-model="bedrooms"
                  value="3"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">3</label>
              </div>
              <div>
                <input
                  id="4"
                  v-model="bedrooms"
                  value="4"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">4</label>
              </div>
            </div>
          </div>
          <div class="w-50">
            <label>Vagas de garagem</label>
            <div class="d-flex gap-3 align-items-center">
              <div>
                <input
                  id="1"
                  v-model="garage"
                  value="1"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">1</label>
              </div>
              <div>
                <input
                  id="2"
                  v-model="garage"
                  value="2"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">2</label>
              </div>
              <div>
                <input
                  id="3"
                  v-model="garage"
                  value="3"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">3</label>
              </div>
              <div>
                <input
                  id="4"
                  v-model="garage"
                  value="4"
                  class="inputcheckbox"
                  type="checkbox"
                >
                <label class="ps-2">4</label>
              </div>
            </div>
          </div>
          <div class="d-flex mb-2 w-100">
            <Field
              name="online"
              type="checkbox"
              :value="true"
              class="inputcheckbox"
            />
            <LabelFromYup
              name="online"
              :schema="schema"
              class="ps-2 pb-0"
            />
          </div>
        </div>
      </div>
      <div class="d-flex flex-column align-items-center mt-4">
        <button
          class="btnAmarelo"
          :disabled="isSubmitting"
        >
          Salvar
        </button>
        <font-awesome-icon
          v-show="isSubmitting"
          icon="spinner"
          class="spinner mt-3"
        />
      </div>
    </form>
  </div>
  <div v-else>
    <div class="d-flex justify-content-start gap-2 mb-2">
      <router-link
        to="/"
        class="btnVoltar"
      >
        <font-awesome-icon icon="circle-left" />
      </router-link>
      <a
        v-if="lista?.pdfMedia"
        :href="lista.pdfMedia"
        class="btnBranco"
        target="_blank"
      >
        <font-awesome-icon icon="file-pdf" />
        Imprimir PDF
      </a>

      <a
        v-if="lista?.excelMedia"
        :href="lista.excelMedia"
        class="btnBranco"
        target="_blank"
      >
        <font-awesome-icon icon="file-pdf" />
        Média dos valores
      </a>
    </div>
    <div class="card-branco">
      <h1 class="mb-3">Valor Médio</h1>
      <div class="d-flex gap-3">
        <div class="relatorio bordaCinza p-3 w-100">
          <ul class="p-0 m-0">
            <li>Tipo de Imóvel: <strong>{{ lista?.filtros?.type }}</strong></li>
            <li>Negócio: <strong>{{ lista?.filtros?.business_type }}</strong></li>
            <li>Cidade: <strong>{{ lista?.filtros?.city }}</strong></li>
            <li>Bairro: <strong>{{ lista?.filtros?.neighborhood }}</strong></li>
            <li>Data Inicial: <strong>{{ dateToField(lista?.filtros?.updated_at_gt) }}</strong></li>
            <li>Data Final: <strong>{{ dateToField(lista?.filtros?.updated_at_lt) }}</strong></li>
          </ul>
        </div>

        <div class="relatorio bordaCinza p-3 w-100">
          <ul class="p-0 m-0">
            <li>
              Valor Médio Metro Quadrado Área Total: 
              <strong>R$/m2 {{ dinheiro(lista?.areaMediaTotal) }}</strong>
            </li>
            <li>
              Valor Médio Metro Quadrado Área Útil: 
              <strong>R$/m2 {{ dinheiro(lista?.mediaAreaUtil) }}</strong>
            </li>
            <li>
              Valor Médio das Amostras: 
              <strong>R$ {{ dinheiro(lista?.mediaValores) }}</strong>
            </li>
            <li>
              Total de Ativas: 
              <strong>{{ lista?.countAmostrasAtivas }}</strong>
            </li>
            <li>
              Total de Inativas: 
              <strong>{{ lista?.countAmostrasInativas }}</strong>
            </li>
            <li>
              Total de Amostras: 
              <strong>{{ lista?.countAmostrasAtivas }}</strong>
            </li>
          </ul>
        </div>
      </div>

      <div
        v-if="lista?.amostras?.data" 
        class="tabela mt-3"
      >
        <table class="w-100">
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
          <col>
          <thead>
            <tr>
              <th>
                Endereço/Bairro
              </th>
              <th>
                Tipo
              </th>
              <th>
                Negocio
              </th>
              <th>
                Quartos
              </th>
              <th>
                Vagas
              </th>
              <th>
                Valor
              </th>
              <th>
                Á. Priv.
              </th>
              <th>
                Á. Total
              </th>
              <th>
                R$/m² priv.
              </th>
              <th>
                R$/m² total
              </th>
              <th>
                Anunciante
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in lista?.amostras.data"
              :key="item.id"
            >
              <td>{{ item.bairro }}</td>
              <td>{{ item.tipo }}</td>
              <td>{{ item.tipo_negocio }}</td>
              <td>{{ item.quartos }}</td>
              <td>{{ item.vagas }}</td>
              <td>R${{ dinheiro(item.preco) }}</td>
              <td>{{ item.area + `m²` }}</td>
              <td>{{ item.area_total + `m²` }}</td>
              <td>R${{ dinheiro(item.preco / item.area) }}</td>
              <td>R${{ dinheiro(item.preco / item.area_total) }}</td>
              <td class="anunciante">
                <a v-if="item.site" :href="item.site">Ver anuncio</a>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-if="paginacao" 
          class="d-flex gap-2 justify-content-center mt-4"
        >
          <button
            v-for="(link, index) in paginacao.links.slice(1, -1)"
            :key="index"
            class="paginacao-btn"
            :disabled="!link.url"
            :class="link.active ? 'paginacaoAtiva' : ''"
            @click="mudarPagina(link)"
          >
            {{ link.label }}
          </button>     
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { relatorio as schema } from '@/consts/formSchema';
import portal from '@/consts/portal';
import tipoImovel from '@/consts/tipoImovel';
import tipoNegocio from '@/consts/tipoNegocio';
import dateToField from '@/helpers/dateToFiel';
import dinheiro from '@/helpers/dinheiro';
import { useAlertStore } from '@/store/alert.store';
import { useCidadeStore } from '@/store/buscaCidade.store';
import { useRelatorioStore } from '@/store/relatorioAmostras.store';
import { storeToRefs } from 'pinia';
import { ErrorMessage, Field, useForm } from 'vee-validate';
import { computed, onMounted, ref } from 'vue';
import Multiselect from 'vue-multiselect';

const alertStore = useAlertStore();

const relatorioAmostraStore = useRelatorioStore()
const { lista, paginacao } = storeToRefs(relatorioAmostraStore)

const cidadeStore = useCidadeStore()
const { cidade, listaBairro } = storeToRefs(cidadeStore)

const bedrooms = ref([])
const garage = ref([])

const {
  errors, isSubmitting, handleSubmit, values
} = useForm({
  validationSchema: schema,
});

const optionsCity = computed(() => {
  if (!cidade.value || !Array.isArray(cidade.value)) {
    return [];
  }

  return cidade.value.reduce((acc, estado) => {
    if (estado.cidades && Array.isArray(estado.cidades)) {
      const nomesCidades = estado.cidades.map((cidade) => ({
        valor: cidade.nome,
        id: cidade.id
      }));
      acc.push(...nomesCidades);
    }
    return acc;
  }, []);
});

const selectedNeighborhood = ref([])
const optionsNeighborhood = computed(() => {
  if (!listaBairro.value || !Array.isArray(listaBairro.value)) {
    return [];
  }

  return listaBairro.value.map((bairro) => ({
    valor: bairro.nome, 
    id: bairro.id
  }));
});

const onSubmitAmostras = handleSubmit(async (values) => {
  try {
    const payload = {
      ...values,
      'neighborhood': selectedNeighborhood.value.map( 
      item => item.valor
      ),
    }
    console.log(payload)
    await relatorioAmostraStore.relatorioAmostras(payload);
  } catch (error) {
    alertStore.error(error);
  }
})

async function getBairro(e) {
  try{
    const estado = e.target.value
    await cidadeStore.buscarBairro(estado)
  } catch( error){
    alertStore.error(error);
  }
}

async function iniciar(){
  await cidadeStore.buscarCidade()
}
iniciar()

async function mudarPagina(link) {
  if (link){
    await relatorioAmostraStore.mudarPagina(link.label)
  }
}

onMounted(() => {
  relatorioAmostraStore.lista = null;
});

</script>

<style scoped>
.ativo svg {
  color: var(--roxo);
  background-color: initial;
  margin-left: initial;
}

.relatorio li{
  list-style: none;
  color: var(--cinzaEscuro);
  text-decoration: none;
  padding-bottom: 7px;
}
</style>